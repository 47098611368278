/* styles */
import 'swiper/swiper.scss';
import '../scss/main.scss';

/* scripts */
let stepsCounterArr = [];
let formTabArr = [];
let formTabField = [];
let formTabFieldFilled = [];
document.querySelectorAll('.steps__counter').forEach(stepsCounter => {
	stepsCounterArr.push(stepsCounter);
});

document.querySelectorAll('.form__tab').forEach(formTab => {
	formTabArr.push(formTab);
	if(formTab.querySelector('button[type="button"]')) {
		formTab.querySelector('button[type="button"]').addEventListener('click', (e) => {
			formTabField = [];
			formTabFieldFilled = [];
			e.target.closest('.form__tab').querySelectorAll('.form__field').forEach(formField => {
				formTabField.push(formField);
				if(formField.value){
					formTabFieldFilled.push(formField);
					formField.parentNode.classList.remove('form__item_error');
				} else {
					formField.parentNode.classList.add('form__item_error');
				}
			});

			if(formTabField.length === formTabFieldFilled.length) {
				e.target.closest('.form__tab').classList.remove('active');
				e.target.closest('.form__tab').nextElementSibling.classList.add('active');

				stepsCounterArr.forEach(stepsCounterEl => {
					stepsCounterEl.classList.remove('active');
				});
				stepsCounterArr[formTabArr.indexOf(e.target.closest('.form__tab')) + 1].classList.add('active');
			}
		});
	};
});

new Swiper('.promo', {
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 1000,
    /*
  	autoplay: {
		delay: 7000,
		disableOnInteraction: false,
	}
    */
});

document.querySelectorAll('.slides_4col').forEach(slides_4col => {
	new Swiper(slides_4col, {
		slidesPerView: 'auto',
		spaceBetween: 0,
		breakpoints: {
			375: {
				slidesPerView: 'auto',
			},
			768: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
			},
		},
		navigation: {
			nextEl: '.swiper__btn_next',
			prevEl: '.swiper__btn_prev',
		},
	});
});

document.querySelectorAll('.slides_3col').forEach(slides_3col => {
	new Swiper(slides_3col, {
		slidesPerView: 'auto',
		spaceBetween: 0,
		breakpoints: {
			375: {
				slidesPerView: 'auto',
			},
			992: {
				slidesPerView: 2,
			},
			1200: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: '.swiper__btn_next',
			prevEl: '.swiper__btn_prev',
		},
	});
});

/* header */
window.addEventListener('scroll', () => {
    if(window.scrollY > document.querySelector('.header').scrollHeight) {
        document.querySelector('.nav').classList.add('nav_affix');
    } else {
        document.querySelector('.nav').classList.remove('nav_affix');
    }
});

/* nav */
document.querySelectorAll('.nav__arrow').forEach(navArrow => {
	navArrow.addEventListener('click', (e) => {
		e.target.parentNode.classList.toggle('active');
	});
});

document.querySelector('.nav__toggler').addEventListener('click', (e) => {
	document.querySelector('.nav__inner').classList.toggle('active');
});

/* accordion */
document.querySelectorAll('.accordion__title').forEach(accordionTitle => {
	accordionTitle.addEventListener('click', (e) => {
		e.target.parentNode.classList.toggle('active');
	});
});

/* forms */
import Inputmask from 'inputmask';

document.querySelectorAll('.input-email').forEach(field => {
	Inputmask({
		alias: 'email',
        showMaskOnHover: false,
	}).mask(field);
});

document.querySelectorAll('.input-phone').forEach(field => {
	Inputmask({
		mask: '+7 (999) 999-99-99',
        showMaskOnHover: false,
	}).mask(field);
});

let orderForm;
document.querySelectorAll('.btn-data').forEach(btnData => {
	btnData.addEventListener('click', (e) => {
		orderForm = document.querySelector(e.target.getAttribute('data-target'));
		orderForm.querySelector('.form__field_title').value = e.target.getAttribute('data-title');
		orderForm.querySelector('.form__field_price').value = e.target.getAttribute('data-price');
	});
});